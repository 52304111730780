import PropTypes from 'prop-types';

const CLUB_PROP_TYPES = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  colours: PropTypes.shape({
    primary: PropTypes.string.isRequired,
    primaryContrast: PropTypes.string.isRequired,
    primaryDark: PropTypes.string.isRequired,
    primaryDarkContrast: PropTypes.string.isRequired,
    secondary: PropTypes.string.isRequired,
    secondaryContrast: PropTypes.string.isRequired,
  }).isRequired,
  includeAdverts: PropTypes.bool.isRequired,
  galleryEnabled: PropTypes.bool.isRequired,
  advertisingTier: PropTypes.number.isRequired,
  sport: PropTypes.string.isRequired,
  postcode: PropTypes.string,
  gender: PropTypes.string.isRequired,
  isYouth: PropTypes.bool.isRequired,
  twitterAccount: PropTypes.string,
  fundraisingClub: PropTypes.shape({
    beEthicalCode: PropTypes.string,
    causeInformation: PropTypes.string,
  }),
});

export default CLUB_PROP_TYPES;
